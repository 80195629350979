import Link from "next/link";

export default function Custom404() {
  return (
    <div className="container">
      <div className="py-16 w-screen h-96 bg-wind flex justify-between content-center flex-wrap flex-col">
        <p className="text-3xl md:text-6xl font-bold text-blue leading-tight">
          Oops! Something is missing.
        </p>
        <p className="font-normal leading-tight">
          There was a problem finding this page.
        </p>
        <p className="font-normal leading-tight">
          <span className="opacity-50">Take me back to</span>{" "}
          <Link href="/">
            <a className="border-b">the home page.</a>
          </Link>
        </p>
      </div>
    </div>
  );
}
